import {
  AuthenticatedRequest,
  DeleteRequestConfig,
  GetRequestConfig,
  PostRequestConfig,
  tryAuthenticatedWithRefresh,
} from './internal'
import { isoToRegion, slugToIso } from '~/services/i18n'
import appConfig from '~/app.config'

const endpoint = (locale: string) =>
  isoToRegion(slugToIso(locale)) === 'US'
    ? process.env.API_US_ENDPOINT
    : process.env.API_EU_ENDPOINT

const headers = { accept: 'application/json' }

type PyramidGeolocationRequest = {
  locale: string
}

export type PyramidConfigResponse = {
  region: {
    mapped_region: 'EU' | 'CH' | 'UK' | 'US'
    original_iso_code: string  // z.B. 'DE', 'FR', 'AU'
  }
}

export const pyramidConfig = ({
  locale,
}: PyramidGeolocationRequest): GetRequestConfig => ({
  url: `${endpoint(locale)}/config`,
  config: { headers },
})

type PyramidPatchUserSettingsRequest = {
  locale: string
  userId: string
  key: string
  value: string | boolean
}

export type PyramidPatchUserSettingsResponse = {}

export const pyramidPatchUserSettings = ({
  locale,
  token,
  userId,
  key,
  value,
}: PyramidPatchUserSettingsRequest &
  AuthenticatedRequest): PostRequestConfig => ({
  url: `${endpoint(locale)}/user/${userId}/settings`,
  data: {
    key,
    value,
  },
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

type PyramidGetUserSettingsRequest = {
  locale: string
  userId: string
}

export type PyramidGetUserSettingsResponse = {
  key: string
  value: string
}[]

export const pyramidGetUserSettings = ({
  locale,
  token,
  userId,
}: PyramidGetUserSettingsRequest & AuthenticatedRequest): GetRequestConfig => ({
  url: `${endpoint(locale)}/user/${userId}/settings`,
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

type PyramidPostPlanRequest = {
  locale: string
  playlist: string
}

export type PyramidPostPlanResponse = {}

export const pyramidPostPlan = ({
  locale,
  token,
  playlist,
}: PyramidPostPlanRequest & AuthenticatedRequest): PostRequestConfig => ({
  url: `${endpoint(locale)}/plan`,
  data: {
    playlist,
  },
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

type PyramidGetPlanRequest = {
  locale: string
}

export type PyramidGetPlanResponseContent = {
  ctime: string
  event: string
  plan: string
  playlist: string
  user: string
}

export type PyramidGetPlanResponse = {
  [key: number]: PyramidGetPlanResponseContent
}

export const pyramidGetPlan = ({
  locale,
  token,
}: PyramidGetPlanRequest & AuthenticatedRequest): GetRequestConfig => ({
  url: `${endpoint(locale)}/plan`,
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

type PyramidDeletePlanRequest = {
  locale: string
  uuid: string
}

export type PyramidDeletePlanResponse = {}

export const pyramidDeletePlan = ({
  locale,
  token,
  uuid,
}: PyramidDeletePlanRequest & AuthenticatedRequest): DeleteRequestConfig => ({
  url: `${endpoint(locale)}/plan/${uuid}`,
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

type PyramidGetPlanWorkoutRequest = {
  locale: string
  uuid: string
}

export type PyramidGetPlanWorkoutResponseContent = {
  ctime: string
  plan: string
  user: string
  workout: string
}

export type PyramidGetPlanWorkoutResponse =
  PyramidGetPlanWorkoutResponseContent[]

export const pyramidGetPlanWorkout = ({
  locale,
  token,
  uuid,
}: PyramidGetPlanWorkoutRequest & AuthenticatedRequest): GetRequestConfig => ({
  url: `${endpoint(locale)}/plan/${uuid}/workout`,
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

type PyramidPostPlanWorkoutRequest = {
  locale: string
  plan: string
  workout: string
}

export type PyramidPostPlanWorkoutResponse = {}

export const pyramidPostPlanWorkout = ({
  locale,
  token,
  plan,
  workout,
}: PyramidPostPlanWorkoutRequest &
  AuthenticatedRequest): PostRequestConfig => ({
  url: `${endpoint(locale)}/plan/${plan}/workout`,
  data: {
    workout,
  },
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

type PyramidSignUpRequest = {
  locale: string
  mail: string
  region: string
  language: string
  didBuyBellicon: boolean
  extendedTrial: string
  password: string
  coupon?: string
}

export type PyramidSignUpResponse = {
  user_id: string
  status: string
  message: string
  monthly_subscription: any // Hier sollten Sie den korrekten Typ hinzufügen
  yearly_subscription: any // Hier sollten Sie den korrekten Typ hinzufügen
}

export type PyramidPasswordResetResponse = {
  status: string
  message: string
}

export type PyramidPasswordResetEndResponse = {
  status: string
  message: string
}

export const pyramidSignUp = ({
  locale,
  mail,
  region,
  language,
  didBuyBellicon,
  extendedTrial,
  password,
  coupon
}: PyramidSignUpRequest): PostRequestConfig => ({
  url: `${endpoint(locale)}/auth/signup`,
  data: {
    signin_id: mail,
    region,
    language,
    didBuyBellicon,
    extendedTrial,
    password,
    coupon
  },
  config: { headers },
})

type PyramidPasswordResetStartRequest = {
  mail: string
  locale: string
}

type PyramidPasswordResetEndRequest = {
  password: string
  id: string
  locale: string
}

type FusionAuthVerifyRequest = {
  id: string
}

export const pyramidPasswordResetStart = ({
  mail,
  locale,
}: PyramidPasswordResetStartRequest): PostRequestConfig => ({
  url: `${endpoint(locale)}/auth/reset-password`,
  data: {
    signin_id: mail,
    sendForgotPasswordEmail: false,
  },
  config: { headers },
})

export const pyramidPasswordResetEnd = ({
  password,
  id,
  locale,
}: PyramidPasswordResetEndRequest): PostRequestConfig => ({
  url: `https://bellicon.fusionauth.io/api/user/change-password`,
  data: {
    changePasswordId: id,
    password,
  },
  config: { headers },
})

export const fusionAuthEmailVerify = ({
  id,
}: FusionAuthVerifyRequest): PostRequestConfig => ({
  url: `https://bellicon.fusionauth.io/api/user/verify-registration/`,
  data: {
    verificationId: id,
  },
  config: { headers },
})

type PyramidSignInRequest = {
  locale: string
  mail: string
  password: string
}

export type PyramidSignInResponse = {
  token: string
  refreshToken: string
  user: { email: string }
}

export const pyramidSignIn = ({
  locale,
  mail,
  password,
}: PyramidSignInRequest): PostRequestConfig => ({
  url: `${endpoint(locale)}/auth/signin`,
  data: { signin_id: mail, password },
  config: { headers },
})

export type PyramidVideoTokenRequest = {
  locale: string
  signature: string
}

export type PyramidVideoTokenResponse = {
  token: string
}

const pyramidVideoToken = ({
  locale,
  signature,
  token,
}: PyramidVideoTokenRequest & AuthenticatedRequest): PostRequestConfig => ({
  url: `${endpoint(locale)}/video/token`,
  data: { signature },
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

export const pyramidTryVideoToken = tryAuthenticatedWithRefresh<
  PyramidVideoTokenRequest,
  PyramidVideoTokenResponse
>(pyramidVideoToken, 'post')

type PyramidSubscriptionCreateRequest = {
  locale: string
  subscription: (typeof appConfig.regions)[number]['platform'][
    | 'monthly'
    | 'yearly']['chargebeeSubscription']
  coupon?: string
}

export type PyramidSubscriptionCreateResponse = {
  url: string
}

const pyramidSubscriptionCreate = ({
  locale,
  token,
  subscription,
  coupon = undefined,
}: PyramidSubscriptionCreateRequest &
  AuthenticatedRequest): PostRequestConfig => ({
  url: `${endpoint(locale)}/subscription/create/`,
  data: { subscription, coupon },
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

export const pyramidTrySubscriptionCreate = tryAuthenticatedWithRefresh<
  PyramidSubscriptionCreateRequest,
  PyramidSubscriptionCreateResponse
>(pyramidSubscriptionCreate, 'post')

type PyramidSubscriptionManageRequest = {
  locale: string
}

export type PyramidSubscriptionManageResponse = {
  url: string
}

const pyramidSubscriptionManage = ({
  locale,
  token,
}: PyramidSubscriptionManageRequest &
  AuthenticatedRequest): PostRequestConfig => ({
  url: `${endpoint(locale)}/subscription/manage/`,
  data: {},
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

type PyramidPostVideoStarsRequest = {
  locale: string
  userId: string
  uuid: string
}

export type PyramidPostVideoStarsResponse = {}

export const pyramidPostVideoStars = ({
  locale,
  token,
  userId,
  uuid,
}: PyramidPostVideoStarsRequest & AuthenticatedRequest): PostRequestConfig => ({
  url: `${endpoint(locale)}/user/${userId}/bookmark`,
  data: {
    video: uuid,
  },
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

type PyramidGetVideoStarsRequest = {
  locale: string
  userId: string
}

export type PyramidGetVideoStarsResponse = string[]

export type PyramidCreateViewedVideoRequest = {
  locale: string
  uuid: string
  signature: string
}

export type PyramidCreateViewedVideoResponse = {}

export const pyramidCreateViewedVideo = ({
  locale,
  token,
  uuid,
  signature,
}: PyramidCreateViewedVideoRequest &
  AuthenticatedRequest): PostRequestConfig => ({
  url: `${endpoint(locale)}/video/watched`,
  data: {
    video_id: uuid,
    signature,
  },
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

export const pyramidTryCreateViewedVideo = tryAuthenticatedWithRefresh<
  PyramidCreateViewedVideoRequest,
  PyramidCreateViewedVideoResponse
>(pyramidCreateViewedVideo, 'post')

export type PyramidGetViewedVideosRequest = {
  locale: string
}

export type PyramidGetViewedVideosResponse = {
  watched_videos_list: { [key: string]: string[] }
}

export const pyramidGetViewedVideos = ({
  locale,
  token,
}: PyramidGetViewedVideosRequest & AuthenticatedRequest): GetRequestConfig => ({
  url: `${endpoint(locale)}/video/watched-all`,
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

export const pyramidTryGetViewedVideos = tryAuthenticatedWithRefresh<
  PyramidGetViewedVideosRequest,
  PyramidGetViewedVideosResponse
>(pyramidGetViewedVideos, 'get')

export const pyramidGetVideoStars = ({
  locale,
  token,
  userId,
}: PyramidGetVideoStarsRequest & AuthenticatedRequest): GetRequestConfig => ({
  url: `${endpoint(locale)}/user/${userId}/bookmark`,
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  },
})

type PyramidDeleteVideoStarsRequest = {
  locale: string
  userId: string
  uuid: string
}

export type PyramidDeleteVideoStarsResponse = {}

export const pyramidDeleteVideoStars = ({
  locale,
  token,
  userId,
  uuid,
}: PyramidDeleteVideoStarsRequest &
  AuthenticatedRequest): DeleteRequestConfig => ({
  url: `${endpoint(locale)}/user/${userId}/bookmark`,
  config: {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
    data: {
      video: uuid,
    },
  },
})

export const pyramidTrySubscriptionManage = tryAuthenticatedWithRefresh<
  PyramidSubscriptionManageRequest,
  PyramidSubscriptionManageResponse
>(pyramidSubscriptionManage, 'post')

export const pyramidTryPatchUserSettings = tryAuthenticatedWithRefresh<
  PyramidPatchUserSettingsRequest,
  PyramidPatchUserSettingsResponse
>(pyramidPatchUserSettings, 'patch')

export const pyramidTryGetUserSettings = tryAuthenticatedWithRefresh<
  PyramidGetUserSettingsRequest,
  PyramidGetUserSettingsResponse
>(pyramidGetUserSettings, 'get')

export const pyramidTryPostPlan = tryAuthenticatedWithRefresh<
  PyramidPostPlanRequest,
  PyramidPostPlanResponse
>(pyramidPostPlan, 'post')

export const pyramidTryGetPlan = tryAuthenticatedWithRefresh<
  PyramidGetPlanRequest,
  PyramidGetPlanResponse
>(pyramidGetPlan, 'get')

export const pyramidTryDeletePlan = tryAuthenticatedWithRefresh<
  PyramidDeletePlanRequest,
  PyramidDeletePlanResponse
>(pyramidDeletePlan, 'delete')

export const pyramidTryGetPlanWorkout = tryAuthenticatedWithRefresh<
  PyramidGetPlanWorkoutRequest,
  PyramidGetPlanWorkoutResponse
>(pyramidGetPlanWorkout, 'get')

export const pyramidTryPostPlanWorkout = tryAuthenticatedWithRefresh<
  PyramidPostPlanWorkoutRequest,
  PyramidPostPlanWorkoutResponse
>(pyramidPostPlanWorkout, 'post')

export const pyramidTryPostVideoStars = tryAuthenticatedWithRefresh<
  PyramidPostVideoStarsRequest,
  PyramidPostVideoStarsResponse
>(pyramidPostVideoStars, 'post')

export const pyramidTryGetVideoStars = tryAuthenticatedWithRefresh<
  PyramidGetVideoStarsRequest,
  PyramidGetVideoStarsResponse
>(pyramidGetVideoStars, 'get')

export const pyramidTryDeleteVideoStars = tryAuthenticatedWithRefresh<
  PyramidDeleteVideoStarsRequest,
  PyramidDeleteVideoStarsResponse
>(pyramidDeleteVideoStars, 'delete')
